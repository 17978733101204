import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Sucursal extends RESTModel {
    public id: number;
    public company: string;
    public oficina: string;
    public sucursalNum: number;

    constructor(service: RESTService<Sucursal>, data: any) {
        super(service, data);
    }
}