import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';

import { RESTService } from '../../services/rest.service';

// Models
import { Policy } from '../../models/policy.model';

@Injectable()
export class PolicyService extends RESTService<Policy> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getValue()) {
                    const JWTToken  = new NbAuthJWTToken(token.getValue());
                    //JWTToken.setValue(token.getValue());

                    const tokenPayload = JWTToken.getPayload();
                    this.currentUserId = tokenPayload.id;
                }
            });
    }

    // GET /Polizas

    public getAllPoliciesFromUser(
        UserId: any,
        page: any,
        size: any,
        search: any,
        user2: any = null
    ): Promise<any> {

        let param_page = '';
        if(page !== null){
            param_page = '?page=' + page;
        }

        let param_size = '';
        if(size !== null){
            param_size = '&size=' + size;
        }

        let param_search = '';
        if(search !== ''){
            if(page !== null){
                param_search = '&searchColumn1=policyNum&search1=' + search;
            }else{
                param_search = '?searchColumn1=policyNum&search1=' + search;
            }
        }

        let param_user = '';
        if(user2 !== null){
            param_user = '&user2=' + user2;
        }

        this.setApiEndpoint(
            '/datashow/user/' +
            UserId +
            '/projectslite' + param_page + param_size + param_search + param_user
        );
        return this.getOne();
    }

    public getAllPolicies(): Promise<Policy[]> {

        this.setApiEndpoint(
            '/datapull/datapolicies'
        );
        return this.getAll();
    }

    public getPoliciesOfUsers(
        users: any, 
        policyid: any, 
        incisoid: any
        ): Promise<Policy[]> {

            let query = '';

            if(users){ query = '?users=' + users; }
            if(policyid){ query = query + '&policyid=' + policyid; }
            if(incisoid){ query = query + '&incisoid=' + incisoid; }

        this.setApiEndpoint(
            '/datashow/userpolicyshow' + query
        );
        return this.getAll();
    }

    public getPoliciesByUsers(
        policyid: any, 
        ): Promise<any> {
        this.setApiEndpoint(
            '/policydespacho/policy/' + policyid
        );
        return this.getOne();
    }

    // POST /Polizas

    public setPolicies(
        UserId: any,
        PolicyId: any
    ): Promise<Policy> {
        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/policies/' +
            PolicyId 
        );
        return this.save(PolicyId);
    }

    public setPoliciesv2(
        despachos: any,
        PolicyId: any
    ): Promise<Policy> {
        this.setApiEndpoint(
            '/despachos/assingmulti/policy/' +
            PolicyId 
        );
        return this.save(despachos);
    }

    // POST /Project

    public setProject(
        UserId: any,
        projectid: any
    ): Promise<Policy> {

        this.setApiEndpoint(
            '/user/' +
            UserId +
            '/project/' +
            projectid 
        );
        return this.save(projectid);
    }

        // POST /Project

    public deleteProject(
        UserId: any,
        projectid: any
    ): Promise<any> {
        
        this.setApiEndpoint(
            '/user/' +
            UserId +
            '/project'
        );
        return this.delete(projectid);
    }

    // PATCH /Polizas

    public setRolPolicies(
        UserId: any,
        PolicyId: any,
        data: any
    ): Promise<Policy> {

        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/policies/' +
            PolicyId
        );
        return this.patch(data);
    }

    // DELETE /Polizas

    public deletePolicies(
        UserId: any,
        PolicyId: any
    ): Promise<boolean> {
        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/policies'
        );
        return this.delete(PolicyId);
    }

    public deletePoliciesv2(
        despachos: any,
        PolicyId: any
    ): Promise<any> {
        this.setApiEndpoint(
            '/despachos/assingmulti/policy'
        );
        return this.deletev2(PolicyId, despachos);
    }



    public newResource(data: any): Policy {
        return new Policy(this, data);
    }
}
