import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';

import { RESTService } from '../../services/rest.service';

// Models
import { Group } from '../../models/group.model';

@Injectable()
export class GroupPolygonService extends RESTService<Group> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getValue()) {
                    const JWTToken  = new NbAuthJWTToken(token.getValue());
                    //JWTToken.setValue(token.getValue());

                    const tokenPayload = JWTToken.getPayload();
                    this.currentUserId = tokenPayload.id;
                }
            });
    }

    // GET /Groups

    public getAllGroups(
        UserId: any,
        nogroup: any = false
    ): Promise<Group[]> {

        let get_nogroups = '';

        if(nogroup){
            get_nogroups = '?nogroup=true';
        }

        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/groups' + get_nogroups
        );
        return this.getAll();
    }

    // GET /Groups and polygons

    public getAllGroupsPolygon(
        UserId: any
    ): Promise<Group[]> {
        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/groupsall'
        );
        return this.getAll();
    }

    // POST /Groups

    public setGroup(data: any): Promise<Group> {
        this.setApiEndpoint(
            '/groups'
        );
        return this.save(data);
    }

    // PUT /Groups

    public editGroup(data: any): Promise<Group> {
        this.setApiEndpoint(
            '/groups'
        );
        return this.update(data);
    }

    // DELETE /Groups

    public deleteGroup(
        GroupId: any,
    ): Promise<boolean> {

        this.setApiEndpoint(
            '/groups'
        );
        return this.delete(GroupId);
    }


    public newResource(data: any): Group {
        return new Group(this, data);
    }
}
