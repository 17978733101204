import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';
import { Subject } from 'rxjs';

import { RESTService } from '../../services/rest.service';

// Models
import { Polygon } from '../../models/polygon.model';

@Injectable()
export class PolygonService extends RESTService<Polygon> {

    private tokenPayload: any;
    private currentUserId: number;
    private listPolygon: any;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getValue()) {
                    const JWTToken  = new NbAuthJWTToken(token.getValue());
                    // JWTToken.setValue(token.getValue());

                    const tokenPayload = JWTToken.getPayload();
                    this.currentUserId = tokenPayload.id;
                }
            });
    }

    public setListPolygon(listPolygon: any): void {
        this.listPolygon = listPolygon;
    }

    public getListPolygon(): Observable<any> {
        return this.listPolygon;
    }

    // GET /poligono

    public getAllPolygon(
    userId = this.currentUserId,
    ): Promise<Polygon[]> {

        this.setApiEndpoint(
            '/users/' +
            userId +
            '/measurements'
        );
        return this.getAll();
    }

    public AllPolygonv2(
    userId = this.currentUserId,
    pageNum: any,
    pageSize: any,
    order: any,
    groupname: any,
    ): Promise<any> {

        this.setApiEndpoint(
            '/datashow/user/' +
            userId +
            '/mispoligonos?page=' + pageNum + '&size=' + pageSize + order + groupname + `&team=${true}`
            
        );
        
        return this.getOne();
    }

    public AllPolygonvNoPaginate(
        userId = this.currentUserId,
        filtro:any,
        ): Promise<any> {
    
            this.setApiEndpoint(
                '/datashow/user/' +
                userId +
                '/mispoligonos' + filtro + `&team=${true}`
                
            );
            
            return this.getOne();
        }
    
		public viewAllTechnicians(
        userId = this.currentUserId
        ): Promise<any> {
    
            this.setApiEndpoint(
                '/datashow/user/' +
                userId +
                '/TotalTecnicoPoligonos'
                
            );
            
            return this.getAll();
        }
    

    // GET /poligono by group

    public getAllPolygonByGroup(
    groupId: any,
    ): Promise<Polygon[]> {

        this.setApiEndpoint(
            '/groups/' +
            groupId +
            '/measurements'
        );
        return this.getAll();
    }

    // POST /poligono
    public createPolygon(
        data: any
    ): Promise<Polygon> {

        this.setApiEndpoint(
            '/measurements'
        );

        return this.save(data);
    }

    // PUT /poligono
    public editPolygon(
        data: any
    ): Promise<Polygon> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.update(data);
    }

    // DELETE /poligono
    public deletePolygon(IdPolygon: number): Promise<boolean> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.delete(IdPolygon);
    }

    // GET ONE /poligono
    // public GetPolygon(IdPolygon: number): Promise<any> {
    //     this.setApiEndpoint(
    //         '/measurements'
    //     );
    //     return this.get(IdPolygon, true);
    // }

    public GetPolygon(IdPolygon: number): Promise<any> {
        this.setApiEndpoint(
            '/measurements/' + IdPolygon + '?notes=true&ratingland=true&verify=true'
        );
        return this.getOne();
    }

    public CheckPolygons(
        polygon: any,
        inVector: any,
        inMsr: any,
        id: any,
    ): Promise<any> {

        let vector = '';
        let season = '';
        let setID = '';

        const ciclo = localStorage.getItem('ciclo');
        if(!inVector && !inMsr && id !== 0){
            setID = '&notInMsr=' + id;
        }

        if(inVector){ 
            vector = '&inVector=' + inVector; 
            season = '&season=' + ciclo; 
            if(id !== 0){
                setID = '&notInVec=' + id;
            }
        }

        let msr = '';

        if(inMsr){ 
            msr = '&inMsr=' + inMsr; 
            if(id !== 0){
                setID = '&notInMsr=' + id;
            }
        }

        this.setApiEndpoint(
            '/vectors/searchempalme?polygon=' + polygon + vector + msr + season + setID
        );
        return this.getOne();
    }

    public ArchivePolygon(
        data: any,
    ): Promise<any> {
        this.setApiEndpoint(
            '/measurements/archive'
        );
        return this.patch(data);
    }

    public GetPolygonGeom(polygon: any): Observable<any> {
        return this.http.get(`https://ran.sinecta.com/polygons?geom=${JSON.stringify(polygon)}`);
    }

    private sendDateSubject = new Subject<string>();
    sendDateObservable = this.sendDateSubject.asObservable();

    sendDate( selectedDate: string ) {
        this.sendDateSubject.next( selectedDate );
    }

    public newResource(data: any): Polygon {
        return new Polygon(this, data);
    }
}
