import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/operator/map';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'async';

// Models
import { RESTModel } from '../models/rest.model';

// Other
import { API_SERVER } from '../configs/constants.config';
@Injectable()
export class DataService {
  protected apiURL: string = API_SERVER.protocol + '://' + API_SERVER.ip + API_SERVER.startpoint;
  protected authToken: string;
  currentUserId;

  constructor(
    public http: HttpClient,
    public authService: NbAuthService) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token) {
        this.authToken = token.getValue();
        const JWTToken = new NbAuthJWTToken(token.getValue());
        //JWTToken.setValue(token.getValue());
        const tokenPayload = JWTToken.getPayload();
        this.currentUserId = tokenPayload.id;
      }
    });
  }

  private getHttpOptions() {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.authToken,
    });
    const options = { headers: headers };
    return options;
  }

    public dataPolices(id: any) {
        return this.http.get(`${this.apiURL}/dataClaim/users/${this.currentUserId}/showSiniestroAlert/${id}`, this.getHttpOptions());
    }

    public dataSucursal() {
      return this.http.get(`${this.apiURL}/users/${this.currentUserId}/projects/v2/mysucursals`, this.getHttpOptions());
  }

    public dataAlerts(id: number, input: string, typeSearch: string) {

        let query = '';

        if(input !== ''){

          query = '&search=' + input;

          switch(typeSearch) {
            case '1':
                query = query + '&searchColumn=siniestroNum';
            break;
            case '2':
                query = query + '&searchColumn=policyNum';
            break;
            case '3':
                query = query + '&searchColumn=asegurado';
            break;
            case '4':
                query = query + '&searchColumn=municipio';
            break;
            case '5':
                query = query + '&searchColumn=causa';
            break;
            case '6':
                query = query + '&searchColumn=beneficiary';
            break;
          }
        }

        let ciclo = localStorage.getItem('alertas_ciclo');
        let season:any = 1;

        if(ciclo){
          season = ciclo;
        }

        return this.http.get(`${this.apiURL}/datashow/user/${id}/misalertashow?season=${season}${query}`, this.getHttpOptions())
    }

    public usersAlert(projectid: number) {
        return this.http.get(`${this.apiURL}/datapull/project/${projectid}/showusers`, this.getHttpOptions())
    }

}
